import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  UserGroupIcon, 
  ServerStackIcon,
  ChatBubbleLeftIcon,
  XMarkIcon 
} from '@heroicons/react/24/outline';

function NavigationMenu({ onLogout }: { onLogout: () => void }) {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  
  useEffect(() => {
    const checkDocuments = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/documents/count`, {
          credentials: 'include'
        });
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.error || 'Failed to check documents');
        }
        
        const { has_documents } = await response.json();
        if (!has_documents) {
          setShowTooltip(true);
        }
      } catch (error) {
        console.error('Failed to check documents:', error);
        if (error instanceof Error && (error.message === 'Unauthorized' || error.message === 'No workspace found')) {
          // Handle specifically if needed
        }
      }
    };

    checkDocuments();
  }, []);

  return (
    <div className="w-64 bg-black text-white p-4 flex flex-col h-full relative">
      <h1 className="text-xl font-bold mb-6 truncate">Volte Knowledge Agent</h1>
      <div className="border-b border-gray-500 mb-4"></div>
      <nav className="flex-1">
        <ul>
          <li className="mb-2">
            <button 
              className="flex items-center w-full p-2 rounded hover:bg-white hover:text-black transition-colors" 
              onClick={() => navigate('/app')}
            >
              <UserGroupIcon className="h-5 w-5 mr-2" />
              Assistants
            </button>
          </li>
          <li className="mb-2 relative">
            <button 
              className="flex items-center w-full p-2 rounded hover:bg-white hover:text-black transition-colors" 
              onClick={() => navigate('/knowledge')}
            >
              <ServerStackIcon className="h-5 w-5 mr-2" />
              Knowledge Store
            </button>

            {/* 
            {showTooltip && (
              <div className="absolute left-full ml-4 top-0 w-64 bg-green-50 text-green-900 p-4 rounded-lg shadow-lg z-50">
                <button 
                  className="absolute top-2 right-2 text-green-500 hover:text-green-700"
                  onClick={() => setShowTooltip(false)}
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
                <p className="mb-3">Your workspace doesn't have any documents yet. Add some to get started!</p>
                <button
                  className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 w-full"
                  onClick={() => {
                    setShowTooltip(false);
                    navigate('/knowledge');
                  }}
                >
                  Got it
                </button>
              </div>
            )} 
            */}
          </li>
        </ul>
      </nav>
      <button
        className="mt-auto bg-blue-500 text-white rounded p-2 flex items-center justify-center hover:bg-blue-600 transition-colors"
        onClick={() => window.location.reload()}
      >
        <ChatBubbleLeftIcon className="h-5 w-5 mr-2" />
        New Chat
      </button>
    </div>
  );
}

export default NavigationMenu;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface LoginProps {
    setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login: React.FC<LoginProps> = ({ setAuthenticated }) => {
    const [loginIdentifier, setLoginIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleEmailLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    email: loginIdentifier,
                    password 
                }),
                credentials: 'include'
            });

            if (response.ok) {
                setAuthenticated(true);
            } else {
                const data = await response.json();
                setError(data.error || 'Login failed');
            }
        } catch (error) {
            console.error('Error during email login:', error);
            setError('An error occurred. Please try again.');
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/google`, {
                method: 'GET',
                credentials: 'include'
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.authUrl) {
                    // Redirect to the auth URL
                    window.location.href = data.authUrl;
                } else {
                    console.error('No authUrl received from server');
                    setError('Failed to initiate Google login. Please try again.');
                }
            } else {
                console.error('Failed to get auth URL from server');
                setError('Failed to initiate Google login. Please try again.');
            }
        } catch (error) {
            console.error('Error during Google login:', error);
            setError('An error occurred. Please try again.');
        }
    };

    // Commenting out Microsoft login until backend is ready
    /*
    const handleMicrosoftLogin = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/microsoft`, {
                method: 'GET',
                credentials: 'include'
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.authUrl) {
                    window.location.href = data.authUrl;
                } else {
                    console.error('No authUrl received from server');
                    setError('Failed to initiate Microsoft login. Please try again.');
                }
            } else {
                console.error('Failed to get auth URL from server');
                setError('Failed to initiate Microsoft login. Please try again.');
            }
        } catch (error) {
            console.error('Error during Microsoft login:', error);
            setError('An error occurred. Please try again.');
        }
    };
    */

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="px-8 py-6 mt-4 text-left bg-white rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold text-center text-gray-800">Login to Your Account</h3>
                <form onSubmit={handleEmailLogin}>
                    <div className="mt-4">
                        <div>
                            <label className="block" htmlFor="loginIdentifier">Email</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                value={loginIdentifier}
                                onChange={(e) => setLoginIdentifier(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block" htmlFor="password">Password</label>
                            <input
                                type="password"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="flex items-baseline justify-between">
                            <button className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">Login</button>
                            <Link to="/forgot-password" className="text-sm text-blue-600 hover:underline">Forgot password?</Link>
                        </div>
                    </div>
                </form>
                {error && <p className="mt-4 text-xs text-red-600">{error}</p>}
                <div className="mt-6 space-y-3">
                    <button
                        onClick={handleGoogleLogin}
                        className="w-full px-4 py-2 flex items-center justify-center space-x-2 transition-colors duration-300 border border-gray-300 rounded-lg hover:bg-gray-100 focus:outline-none"
                    >
                        <img src={require('../assets/google_g_icon.webp')} alt="Google logo" className="w-5 h-5" />
                        <span className="text-gray-700 font-medium">Continue with Google</span>
                    </button>
                    {/* Commenting out Microsoft login button until backend is ready
                    <button
                        onClick={handleMicrosoftLogin}
                        className="w-full px-4 py-2 flex items-center justify-center space-x-2 transition-colors duration-300 border border-gray-300 rounded-lg hover:bg-gray-100 focus:outline-none"
                    >
                        <img src={require('../assets/microsoft_icon.webp')} alt="Microsoft logo" className="w-5 h-5" />
                        <span className="text-gray-700 font-medium">Continue with Microsoft</span>
                    </button>
                    */}
                </div>
            </div>
        </div>
    );
};

export default Login;

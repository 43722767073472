import React from 'react';

const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <header>
        <h1 className="text-3xl font-bold mb-2">Terms of Service</h1>
        <p className="text-gray-600 text-sm mb-8">Effective as of September 20, 2024</p>
      </header>

      <main className="space-y-8">
        <section>
          <h2 className="text-2xl font-semibold mb-4">1. Agreement and Acceptance of Terms</h2>
          
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">About Baseview</h3>
            <p className="text-gray-700 mb-4">
              Baseview was built to help companies get the most out of their data and systems. 
              By advancing the implementation of AI and agent-based systems, Baseview unlocks 
              our customers' most valuable information and empowers its employees to excel in any role.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Description of Services</h3>
            <p className="text-gray-700 mb-4">
              Baseview allows you to create AI-powered experiences on top of your existing data, 
              documents, systems, databases, and more.
            </p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg mb-6">
            <p className="font-semibold">PLEASE READ THIS AGREEMENT AND PRIVACY POLICY CAREFULLY.</p>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Agreement</h3>
            <p className="text-gray-700">
              These Terms of Service (<span className="font-semibold">"Terms of Service"</span> or{' '}
              <span className="font-semibold">"Terms"</span>) and our privacy policy ({' '}
              <span className="font-semibold">"Privacy Policy"</span>) (collectively, this{' '}
              <span className="font-semibold">"Agreement"</span>), govern your access and use of 
              the Baseview website <a href="https://voltetech.com" className="text-blue-600 hover:text-blue-800">
              https://voltetech.com</a> (the "Website"), the Baseview mobile application 
              (the <span className="font-semibold">"App"</span>), and any other services or 
              platforms made available by Baseview from time to time (collectively, the{' '}
              <span className="font-semibold">"Platform"</span>).
            </p>
            <h3 className="text-xl font-semibold mb-2">Changes</h3>
            <p className="text-gray-700 mb-4">
                We reserve the right to modify, amend, or change the Terms at any time. Notice of any material change will be posted on this page with an updated date. In certain circumstances, we may notify you of a change to the Terms via email or other means; however, you are responsible for regularly checking this page for notice of any changes. Your continued use of the Platform constitutes your acceptance of any change, and you will be legally bound by the updated Terms. If you do not accept a change to these Terms, you should stop using the Platform immediately.
            </p>
            <h3 className="text-xl font-semibold mb-2">Disclaimers</h3>
            <p className="text-gray-700 mb-4">
                <strong>BASEVIEW IS NOT RESPONSIBLE FOR AND CANNOT GUARANTEE THE ACCURACY OR TIMELINESS OF INFORMATION WE RETRIEVE ON YOUR BEHALF DIRECTLY, FROM THIRD-PARTY TECHNOLOGY PROVIDERS.</strong> While the App and Platform provided by Baseview may provide significant assistance in helping you and your employees get answers, you should consult with professional services providers before making decisions or deciding on significant changes to your business or business strategy.
            </p>
            <h3 className="text-xl font-semibold mb-2">Ownership</h3>
            <p className="text-gray-700 mb-4">
                You retain all rights to the documents and materials you upload to Baseview. By using our Service, you grant Baseview a non-exclusive, worldwide, royalty-free license to use, store, and process your content solely for the purpose of providing and improving the Service. This license permits us to perform the necessary operations to deliver our AI-powered features and maintain the platform. However, you represent and warrant that your uploaded content does not infringe on any intellectual property rights, contain malicious code or malware, violate any applicable laws, or include sensitive personal data without proper authorization. We reserve the right to remove any content that violates these terms or may pose a risk to our Service or other users.
            </p>
            <h3 className="text-xl font-semibold mb-2">Eligibility</h3>
            <p className="text-gray-700 mb-4">
                To use the Platform, you represent and warrant that you: (a) are at least 18 years of age, and (b) you have not previously been removed from the Platform by us. You may not use the Platform for any illegal or unauthorized purpose nor may you, in the use of the Platform, violate any laws or any person or entity’s rights (including but not limited to copyright laws and rights of publicity). You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services.
            </p>
            <h3 className="text-xl font-semibold mb-2">Acceptance</h3>
            <p className="text-gray-700 mb-4">
                This Agreement contains the complete terms and conditions that govern your use of the Platform. <strong>BY ACCESSING, VISITING, BROWSING, USING, OR ATTEMPTING TO INTERACT WITH OR USE ANY PART OF THIS PLATFORM, YOU AGREE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THIS AGREEMENT.
                THESE TERMS AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS AND INCLUDE AN AGREEMENT TO ARBITRATE IN SECTION 14, A JURY TRIAL WAIVER AND CLASS ACTION WAIVER.</strong> By using the Platform or accepting these Terms, you accept and agree to be bound and abide by these Terms and our Privacy Policy. If you do not agree to this Agreement, please do not use the Platform.
            </p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">2. Your Privacy</h2>
          <p className="text-gray-700 mb-4">
                Our Privacy Policy explains what information may be collected through the Platform, 
                how that information may be used and/or shared with others, how we safeguard that 
                information, and how you may access or control its use in connection with our marketing 
                communications and business activities.
          </p>
          <h3 className="text-xl font-semibold mb-2">Electronic Communications</h3>
          <p className="text-gray-700 mb-4">
                By providing your email to enroll for use of our Services, you consent to receive all notices and information regarding our Platform and other offerings electronically. Electronic communications may be posted on our Dashboard and/or delivered to your registered e-mail address. All communications in electronic format will be considered to be in “writing,” and to have been received no later than five (5) business days after posting or dissemination, whether or not you have received or retrieved the communication. Your consent to receive communications electronically is valid until you end your relationship with Baseview. It is your responsibility to provide us with true, accurate and complete e-mail addresses, contact and other information related to this disclosure and to maintain and update promptly any changes in this information. You may print a copy of any electronic communications and retain it for your records. We reserve the right to terminate or change the terms and conditions on which we provide electronic communications and will provide you notice thereof in accordance with applicable law.
          </p>
          <h3 className="text-xl font-semibold mb-2">SMS/Text Messaging Service</h3>
          <p className="text-gray-700 mb-4">
                You may receive SMS/text messages from Baseview about account-related news and alerts and/or promotional offers for Baseview products and services. By enrolling in Baseview’s services, you agree to receive text messages from Baseview to your mobile phone number provided, and you certify that your mobile number provided is correct and that you are authorized to enroll the designated mobile number to receive such text messages. You acknowledge and agree that the text messages may be sent using an automatic telephone dialing system and that standard message and data rates may apply. Consent to receive text messages for promotional purposes is not required as a condition of purchase or use of the Platform. The text messaging may not be compatible with all mobile phone models. Baseview is not responsible for any delays upon sending or receiving text messages.
                
                To unsubscribe from text messages at any time reply STOP, END or QUIT to any text message you receive from Baseview. You consent that following such a request to unsubscribe, you may receive one final text message from Baseview confirming your request. Note that unsubscribing from promotional and/or account-related texts will not prevent Baseview from sending you text messages for security and authentication purposes.
          </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">3. Accessing the Services and Account Security</h2>
            <p className="text-gray-700 mb-4">
                You agree to provide current, complete and accurate purchase and account information for all purchases made on our Platform. You agree to promptly update your account and other information, including your email address, shipping address as needed.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property Rights</h2>
            <p className="text-gray-700 mb-4">
                The Platform is owned and operated by Baseview. By registering for an account on the Website (“Account”) or by otherwise using the Platform, you agree that all content, trademarks, and other proprietary materials and/or information on the Platform, including, without limitation, Baseview’s logos, visual interfaces, graphics, design, specifications, compilation, information, software, computer code (including source code or object code), services, text, pictures, photos, video, graphics, music, information, data, sound files, other files and the selection and arrangement thereof and all other materials (collectively, the “Baseview Content”) are protected by copyright, trademark, patent, trade secret, and other laws, and, as between you and Baseview (and/or third-party licensors), Baseview owns and retains all rights, title, and interest in the Baseview Content and the Platform.
                
                Unless otherwise expressly stated in writing by us, you are granted a limited, non-exclusive, non-transferable, non-sublicensable, non-assignable, revocable license to access and use the Platform for your own personal use only, provided that, you acknowledge and agree that you do not acquire any ownership rights in or to the Platform and/or the Baseview Content or any Platform Marks by accessing or otherwise using the Platform. The license granted to you herein may be terminated by Baseview at any time, in its sole discretion. All rights not expressly granted in this Agreement are hereby expressly reserved by Baseview.
                
                You will not remove, alter, or conceal any copyright, trademark, service mark, or other proprietary rights notices incorporated in or accompanying the Baseview Content. Nothing contained on the Platform should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Baseview Content displayed on the Platform, and you will not reproduce, modify, adapt, prepare derivative works from, perform, display, publish, distribute, transmit, broadcast, sell, license, or otherwise exploit any of the Baseview Content without the written permission of Baseview or such third party that may own any Platform Marks displayed on the Platform.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">5. Trademarks</h2>
            <p className="text-gray-700 mb-4">
                All trademarks, logos, and service marks (collectively, the “Platform Marks”) are the exclusive property of Baseview (and/or third-party licensors) or other third parties. Nothing in these Terms grants you a license to use any of the Platform Marks or any of the Baseview trade names, trademarks, service marks, logos, domain names, or other distinctive brand features.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">6. User Prohibitions</h2>
            <p className="text-gray-700 mb-4">
                When you use the Platform, you acknowledge and agree that:
                <ul className="list-disc pl-5">
                    <li>You will abide by these Terms.</li>
                    <li>You are solely responsible for your interaction with anyone on the Platform. You agree that Baseview is not responsible or liable for the conduct of any other App user.</li>
                    <li>You will not: (i) reverse engineer, decompile, decode, decrypt, disassemble, or in any way derive source code from, the Platform; (ii) modify, translate, adapt, alter, or create derivative works from the Platform; (iii) copy, distribute, publicly display, transmit, sell, rent, lease or otherwise exploit the Platform; or (iv) distribute, sublicense, rent, lease, loan or grant any third party access to or use of the Platform to anyone.</li>
                    <li>You are solely responsible for obtaining and maintaining all telephone, computer hardware, software, accessories, and other equipment needed for you to access and to use the Platform.</li>
                    <li>You will not impersonate any person or entity; falsely claim an affiliation with any person or entity; access the Accounts of others without permission; forge another person’s digital signature; misrepresent the source, identity, or content of information transmitted via the Platform; or perform any other similar fraudulent activity.</li>
                    <li>You will not use the Platform for any harmful, illegal, nefarious, or untoward purpose.</li>
                    <li>You will not use or attempt to use any cheats, bots, automation software, hacks, or any third-party software designed to interfere with the Platform.</li>
                    <li>You must not stream, broadcast or post or otherwise use information via the Platform that may lead to violations of data privacy laws or other harmful violations of personal identity or privacy, including, without limitation, your or any other person’s credit card information, non-public phone numbers, or non-public e-mail addresses.</li>
                    <li>You will not interfere or disrupt the Platform or its servers or networks connected to the Platform including by transmitting any worms, viruses, spyware, malware, malicious code, or any other code of a destructive or disruptive nature. You may not inject content or code or otherwise alter or interfere with how any Baseview page is rendered or displayed in a browser or on a device.</li>
                    <li>You will not crawl, scrape, cache, or otherwise access any content or information on the Platform via automated means.</li>
                    <li>You will not create an Account through any unauthorized means, including but not limited to, by using an automated device, script, bot, spider, crawler, or scraper. Should you use any automatic, macro, program, or similar method, or otherwise commit fraud with regard to the Platform, Baseview reserves the right to pursue legal action against you.</li>
                    <li>You will not use the Platform for any illegal purpose, or in violation of any local, state, national, or international law or regulation, including without limitation laws governing intellectual property and other proprietary rights, data protection, and privacy.</li>
                    <li>You will not post or share any Prohibited Content, as described below.</li>
                </ul>
                We reserve the right to refuse access to the Platform to you, for any reason at any time, at our sole discretion.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">7. Content Standards</h2>
            <p className="text-gray-700 mb-4">
                We do not tolerate inappropriate content or behavior on our Platform. We encourage you to report any inappropriate content or misconduct by others. You can report any App user directly through the Platform, or by emailing us at support@voltetech.com with the subject “Inappropriate Content.”

                To the extent permitted by applicable law, Baseview takes no responsibility and assumes no liability for Your Content or for any loss or damage resulting therefrom, nor is Baseview liable for any mistakes, defamation, slander, libel, omissions, falsehoods, obscenity, pornography, or profanity you may encounter when using the Platform. Your use of the Platform is at your own risk. In addition, these rules do not create any private right of action on the part of any third party or any reasonable expectation that the Platform will not contain any content that is prohibited by such rules.

                To the fullest extent permitted by applicable law, Baseview reserves the right to remove, screen, or edit any of Your Content posted or stored on the Platform at any time and without notice, including where Your Content violates these Terms or applicable law. Any use of the Platform in violation of the foregoing violates these Terms and may result in, among other things, termination or suspension of your rights to use the Platform.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">8. Reliance on Information Posted</h2>
            <p className="text-gray-700 mb-4">
                We are not responsible if information made available on the Platform is not accurate, complete or current. The material on the Platform is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk. The Platform may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this Platform at any time, but we have no obligation to update any information on our Platform. You agree that it is your responsibility to monitor changes to our Platform.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">9. Geographic Restrictions</h2>
            <p className="text-gray-700 mb-4">
                The owner of this Platform is based in the State of New York in the United States. We provide the Platform for use only by persons located in the United States. We make no claims that the Platform or any of its content is accessible or appropriate outside of the United States. Access to the Platform may not be legal by certain persons or in certain countries. If you access the Platform outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">10. Disclaimer of Warranties</h2>
            <p className="text-gray-700 mb-4">
                THE PLATFORM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. YOUR USE OF THE PLATFORM IS AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, BASEVIEW AND ITS SUBSIDIARIES, AFFILIATES AND ITS AND THEIR RESPECTIVE OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES, AGENTS, AND LICENSEES (THE “<strong>BASEVIEW PARTIES</strong>”) DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHER PROPRIETARY RIGHTS, AND FREEDOM FROM ERRORS, VIRUSES, BUGS, OR OTHER HARMFUL COMPONENTS.

                THE BASEVIEW PARTIES MAKE NO REPRESENTATIONS OR WARRANTIES THAT (A) THE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (B) THE PLATFORM WILL MEET YOUR REQUIREMENTS; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PLATFORM WILL BE ACCURATE OR RELIABLE; (D) THE QUALITY OF THE PLATFORM, ANY PRODUCTS, SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU THROUGH THE PLATFORM WILL MEET YOUR EXPECTATIONS; (E) YOUR INFORMATION CREATED THROUGH THE PLATFORM, IN WHATEVER FORM OR MEDIUM, WILL NOT BE LOST; OR (F) ANY ERRORS WILL BE CORRECTED.
                
                THE BASEVIEW PARTIES SPECIFICALLY DISCLAIM ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY, OR OTHER CAUSE OF ANY KIND BASED UPON OR RESULTING FROM THE PLATFORM.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">11. Limitation of Liability</h2>
            <p className="text-gray-700 mb-4">
                UNDER NO CIRCUMSTANCES WILL THE BASEVIEW PARTIES BE LIABLE TO YOU FOR ANY LOSS OR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO ANY DIRECT, INDIRECT, ECONOMIC, EXEMPLARY, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES) THAT ARE DIRECTLY OR INDIRECTLY RELATED TO: (A) THE PLATFORM; (B) YOUR USE OF, INABILITY TO USE, OR THE PERFORMANCE OF THE PLATFORM; (C) ANY ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY THE BASEVIEW PARTIES OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR OR ANY OTHER PARTY'S USE OF THE PLATFORM; (D) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT OR OTHER INTELLECTUAL PROPERTY OWNERS; (E) ANY ERRORS OR OMISSIONS IN THE PLATFORM’S OPERATION; OR (F) ANY DAMAGE TO ANY OTHER USER’S COMPUTER, MOBILE DEVICE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING, WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS, BUGS, TAMPERING, FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER LINE OR NETWORK FAILURE, OR ANY OTHER TECHNICAL OR OTHER MALFUNCTION WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF FORESEEABLE OR EVEN IF THE BASEVIEW PARTIES HAVE BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL THE BASEVIEW PARTIES BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS, DAMAGE OR INJURY, INCLUDING, WITHOUT LIMITATION, DEATH OR PERSONAL INJURY.
                
                SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. NOTWITHSTANDING THE FOREGOING, IF THE BASEVIEW PARTIES ARE FOUND LIABLE TO YOU FOR ANY DAMAGE OR LOSS ARISING OUT OF OR IN ANY WAY CONNECTED TO YOUR USE OF THE PLATFORM, IN NO EVENT WILL THE BASEVIEW PARTIES TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED FIVE HUNDRED UNITED STATES DOLLARS ($500.00).
                
                BASEVIEW IS NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE THE BASEVIEW PARTIES FROM ANY CLAIMS AND DAMAGES, KNOWN OR UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">12. Indemnification</h2>
            <p className="text-gray-700 mb-4">
                You agree to defend, indemnify and hold the Baseview Parties harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable attorneys’ fees and costs, arising out of or in any way connected to your use or misuse of the Platform or with any of the following (including as a result of your direct activities on the Platform or those conducted on your behalf): (a) your breach or alleged breach of these Terms of Service; (b) your violation of any third-party right, including without limitation, any intellectual property right, publicity, confidentiality, property or privacy right; (c) your violation of any laws, rules, regulations, codes, statutes, ordinances, or orders of any governmental and quasi-governmental authorities, including, without limitation, all regulatory, administrative and legislative authorities; (d) any misrepresentation made by you; or (e) your breach or alleged breach of the representation and warranties set forth in these Terms of Service. Baseview reserves the right to assume the exclusive defense and control of any matter subject to indemnification by you, and you will not in any event settle any claim without the prior written consent of Baseview.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">13. Arbitration</h2>
            <p className="text-gray-700 mb-4">
                ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO THE SERVICES, THIS AGREEMENT OR THE BREACH, TERMINATION, ENFORCEMENT, INTERPRETATION OR VALIDITY THEREOF, INCLUDING THE DETERMINATION OF THE SCOPE OR APPLICABILITY OF THIS AGREEMENT TO ARBITRATE, SHALL BE DETERMINED BY BINDING ARBITRATION RATHER THAN A COURT IN DELAWARE BEFORE A SOLE ARBITRATOR. THE ARBITRATION SHALL BE ADMINISTERED BY JAMS PURSUANT TO STREAMLINED ARBITRATION RULES AND PROCEDURES, AND THE ARBITRATOR SHALL APPLY THE LAWS APPLICABLE IN THE STATE OF DELAWARE. JUDGMENT ON THE AWARD MAY BE ENTERED IN ANY COURT HAVING JURISDICTION AS SPECIFIED ABOVE UNDER “GOVERNING LAW AND VENUE.” THIS CLAUSE SHALL NOT PRECLUDE PARTIES FROM SEEKING INJUNCTIONS OR OTHER FORMS OF EQUITABLE RELIEF OR PROVISIONAL REMEDIES IN AID OF ARBITRATION FROM A COURT OF APPROPRIATE JURISDICTION. YOU AGREE THAT ALL DISPUTES MUST BE BROUGHT IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. BY ENTERING INTO THIS AGREEMENT AND AGREEING TO ARBITRATION, YOU AGREE THAT YOU ARE WAIVING THE RIGHT TO FILE A LAWSUIT AND THE RIGHT TO A TRIAL BY JURY. IN ADDITION, YOU AGREE TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE BASIS. YOU AGREE THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED THESE RIGHTS. IN ANY ARBITRATION, THE ARBITRATOR MAY, IN THE AWARD, ALLOCATE ALL OR PART OF THE COSTS OF THE ARBITRATION, INCLUDING THE FEES OF THE ARBITRATOR AND THE REASONABLE ATTORNEYS’ FEES OF THE PREVAILING PARTY, AND SHALL DETERMINE THE PREVAILING PARTY FOR THIS PURPOSE.
            </p>
        </section>

        <section>
            <h2 className="text-2xl font-semibold mb-4">14. General Provisions</h2>
            <h3 className="text-xl font-semibold mb-2">14.1. Governing Law and Jurisdiction</h3>
            <p className="text-gray-700 mb-4">
                These Terms of Service are governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any principles of conflicts of law. Notwithstanding the foregoing, the Arbitration Agreement in Section 16 above shall be governed by the Federal Arbitration Act. All claims arising out of or relating to this Agreement, to the Platform, or to your relationship with Baseview that for whatever reason are not submitted to arbitration will be litigated exclusively in the federal or state courts of Delaware You and Baseview consent to the exercise of personal jurisdiction of courts in the State of Delaware and waive any claim that such courts constitute an inconvenient forum.
            </p>
            <h3 className="text-xl font-semibold mb-2">14.2. Waiver and Severability</h3>
            <p className="text-gray-700 mb-4">
                Any waiver of any provision of this Agreement will be effective only if in writing and signed by or on behalf of Baseview. No failure or delay by Baseview in exercising any right, power, or privilege under these Terms will operate as a waiver thereof, nor will any single or partial exercise of any right, power, or privilege preclude any other or further exercise thereof or the exercise of any other right, power, or privilege under these Terms. This provision will survive the termination of any and all of your transactions with Baseview. Unless otherwise expressly provided herein, the invalidity or enforceability of any provision of these Terms will not affect the validity or enforcement of any other provision, all of which remain in full force and effect.
            </p>
            <h3 className="text-xl font-semibold mb-2">14.3. Assignment</h3>
            <p className="text-gray-700 mb-4">
                Baseview may assign this Agreement, in whole or in part, at any time with or without notice to you. You may not assign, transfer or sublicense any or all of your rights or obligations under this Agreement without our express prior written consent.
            </p>
            <h3 className="text-xl font-semibold mb-2">14.4. Entire Agreement</h3>
            <p className="text-gray-700 mb-4">
                This Agreement (which includes these Terms of Service, our Privacy Policy and any other Baseview terms that govern your use of Platform), constitutes the entire agreement between you and Baseview.
            </p>
        </section>
        
        <section>
          <h2 className="text-2xl font-semibold mb-4">16. Comments and Concerns</h2>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="mb-2">These Services are operated by Baseview, Inc.</p>
            <address className="not-italic mb-4">
              165 West 91st St<br />
              New York, NY 10024
            </address>
            <p>
              All other feedback, comments, requests for technical support, and other communications 
              relating to the Services should be directed to:{' '}
              <a href="mailto:support@voltetech.com" className="text-blue-600 hover:text-blue-800">
                support@voltetech.com
              </a>
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default TermsOfService;
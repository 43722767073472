import { useState, useEffect } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { PlusIcon, FolderIcon, DocumentIcon, TrashIcon, ChevronLeftIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import NavigationMenu from "./NavigationMenu";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog";
import { Toaster, toast } from 'sonner';

interface Document {
  id: string;
  name: string;
  file_type: string;
  status: string;
  s3_url: string;
  created_at: string;
}

export default function KnowledgeStore() {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState<'name' | 'created_at'>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [deleteConfirmation, setDeleteConfirmation] = useState<{isOpen: boolean; document: Document | null}>({
    isOpen: false,
    document: null
  });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadingFileCount, setUploadingFileCount] = useState({ current: 0, total: 0 });

  useEffect(() => {
    fetchDocuments();
  }, [currentPath]);

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/documents`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch documents');
      const data = await response.json();
      setDocuments(data.documents || []);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setDocuments([]);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length === 0) return;
  
    setIsUploading(true);
    setUploadingFileCount({ current: 0, total: files.length });
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setUploadingFileCount(prev => ({ ...prev, current: i + 1 }));
      setUploadProgress(0);
      
      const formData = new FormData();
      formData.append('files[]', file);
      formData.append('path', currentPath.join('/'));
  
      try {
        const xhr = new XMLHttpRequest();
        
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            setUploadProgress(progress);
          }
        };
  
        await new Promise((resolve, reject) => {
          xhr.open('POST', `${process.env.REACT_APP_API_URL}/api/documents`);
          xhr.withCredentials = true;
          
          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve(xhr.response);
            } else {
              reject(new Error(`Server responded with status: ${xhr.status} ${xhr.statusText}`));
            }
          };
          xhr.onerror = () => reject(new Error('Network Error'));
          xhr.send(formData);
        });
  
        toast.success(`Successfully uploaded ${file.name}`);
      } catch (error: any) {
        console.error('Error uploading file:', error);
        toast.error(`Failed to upload ${file.name}: ${error.message}`);
      }
    }
  
    setIsUploading(false);
    setUploadProgress(0);
    setUploadingFileCount({ current: 0, total: 0 });
    await fetchDocuments();
  };

  const handleDeleteDocument = async (document: Document) => {
    setDeleteConfirmation({ isOpen: true, document });
  };

  const confirmDelete = async () => {
    if (!deleteConfirmation.document) return;
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/documents/${deleteConfirmation.document.id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to delete document');
      await fetchDocuments();
      toast.success(`Successfully deleted ${deleteConfirmation.document.name}`);
    } catch (error) {
      console.error('Error deleting document:', error);
      toast.error(`Failed to delete ${deleteConfirmation.document.name}`);
    } finally {
      setDeleteConfirmation({ isOpen: false, document: null });
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/folders`, {
        method: 'POST',
        credentials: 'include',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          name: newFolderName,
          path: currentPath.join('/'),
        }),
      });
      if (!response.ok) throw new Error('Failed to create folder');
      setNewFolderName('');
      setIsDialogOpen(false);
      await fetchDocuments();
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setIsUploading(true);

    for (const file of files) {
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('files[]', file);
      formData.append('path', currentPath.join('/'));

      try {
        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            setUploadProgress(progress);
          }
        };

        await new Promise((resolve, reject) => {
          xhr.open('POST', `${process.env.REACT_APP_API_URL}/api/documents`);
          xhr.withCredentials = true;
          xhr.setRequestHeader('Accept', 'application/json');
          
          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve(xhr.response);
            } else {
              reject(new Error(`Server responded with status: ${xhr.status} ${xhr.statusText}`));
            }
          };
          xhr.onerror = () => reject(new Error('Network Error'));
          xhr.send(formData);
        });

        toast.success(`Successfully uploaded ${file.name}`);
      } catch (error: any) {
        console.error('Error uploading file:', error);
        toast.error(`Failed to upload ${file.name}: ${error.message}`);
      }
    }

    setIsUploading(false);
    setUploadProgress(0);
    await fetchDocuments();
  };

  const filteredDocuments = documents.filter(doc => 
    doc.name.toLowerCase().includes(searchQuery.toLowerCase())
  ).sort((a, b) => {
    const aValue = a[sortField];
    const bValue = b[sortField];
    const direction = sortDirection === 'asc' ? 1 : -1;
    return aValue < bValue ? -direction : direction;
  });

  const toggleSort = (field: 'name' | 'created_at') => {
    if (sortField === field) {
      setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <NavigationMenu onLogout={() => {}} />
      <div 
        className="flex flex-col flex-grow overflow-hidden"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Toaster position="top-right" />
        <div className="flex-grow overflow-auto p-6">
          <h1 className="text-2xl font-bold mb-6">Knowledge Store</h1>
          
          {/* Navigation and Actions */}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-2">
              <ChevronLeftIcon 
                className={`w-6 h-6 cursor-pointer text-gray-500 hover:text-gray-700 ${
                  currentPath.length === 0 ? 'invisible' : ''
                }`}
                onClick={() => currentPath.length > 0 && setCurrentPath(prev => prev.slice(0, -1))}
              />
              {/* <span className="text-sm text-gray-500">
                {currentPath.length > 0 ? currentPath.join('/') : 'Home'}
              </span> */}
            </div>
            
            <div className="flex items-center gap-4">
              {/* Search Input */}
              <div className="relative">
                <MagnifyingGlassIcon className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <Input
                  type="text"
                  placeholder="Search files..."
                  className="pl-10 w-64"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <Input
                type="file"
                className="hidden"
                id="file-upload"
                multiple
                onChange={handleFileUpload}
              />
              <Button
                variant="default"
                onClick={() => document.getElementById('file-upload')?.click()}
              >
                <PlusIcon className="w-4 h-4 mr-2" />
                Upload
              </Button>
            </div>
          </div>

          {/* Table View */}
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => toggleSort('name')}>
                    <div className="flex items-center">
                      Name
                      {sortField === 'name' && (
                        <span className="ml-1">{sortDirection === 'asc' ? '↑' : '↓'}</span>
                      )}
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => toggleSort('created_at')}>
                    <div className="flex items-center">
                      Last Updated
                      {sortField === 'created_at' && (
                        <span className="ml-1">{sortDirection === 'asc' ? '↑' : '↓'}</span>
                      )}
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredDocuments.length === 0 ? (
                  <tr>
                    <td colSpan={3} className="px-6 py-4 text-center">
                      No documents found
                    </td>
                  </tr>
                ) : (
                  filteredDocuments.map((doc) => (
                    <tr key={doc.id} className="bg-white border-b hover:bg-gray-50">
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        <div
                          className="flex items-center gap-2 cursor-pointer"
                          onClick={() => doc.file_type === 'folder' && 
                            setCurrentPath(prev => [...prev, doc.name])}
                        >
                          {doc.file_type === 'folder' ? (
                            <FolderIcon className="w-5 h-5 text-blue-500" />
                          ) : (
                            <DocumentIcon className="w-5 h-5 text-gray-500" />
                          )}
                          <span>{doc.name}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        {new Date(doc.created_at).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDeleteDocument(doc)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <TrashIcon className="w-4 h-4" />
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Keep existing Dialog component */}
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create New Folder</DialogTitle>
              </DialogHeader>
              <div className="py-4">
                <Input
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  placeholder="Enter folder name"
                  autoFocus
                />
              </div>
              <DialogFooter>
                <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={handleCreateFolder}>
                  Create
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          {/* Delete Confirmation Dialog */}
          <Dialog 
            open={deleteConfirmation.isOpen} 
            onOpenChange={(isOpen) => !isOpen && setDeleteConfirmation({ isOpen: false, document: null })}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Confirm Deletion</DialogTitle>
              </DialogHeader>
              <div className="py-4">
                <p>Are you sure you want to delete "{deleteConfirmation.document?.name}"?</p>
                <p className="text-sm text-gray-500 mt-2">This action cannot be undone.</p>
              </div>
              <DialogFooter>
                <Button 
                  variant="outline" 
                  onClick={() => setDeleteConfirmation({ isOpen: false, document: null })}
                >
                  Cancel
                </Button>
                <Button 
                  variant="destructive" 
                  onClick={confirmDelete}
                >
                  Delete
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          {/* Upload Progress */}
          {isUploading && (
            <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg border w-80">
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium">
                  Uploading file {uploadingFileCount.current} of {uploadingFileCount.total}
                </span>
                <span className="text-sm text-gray-500">{uploadProgress}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
              <div className="mt-2">
                <div className="flex items-center justify-between mb-1">
                  <span className="text-xs text-gray-500">Overall progress</span>
                  <span className="text-xs text-gray-500">
                    {Math.round((uploadingFileCount.current - 1) / uploadingFileCount.total * 100)}%
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-1">
                  <div 
                    className="bg-green-600 h-1 rounded-full transition-all duration-300"
                    style={{ 
                      width: `${Math.round((uploadingFileCount.current - 1) / uploadingFileCount.total * 100)}%` 
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

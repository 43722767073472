import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface PricingTier {
  id: string;
  name: string;
  price: number;
  interval: string;
  features: string[];
}

const pricingTiers: PricingTier[] = [
  {
    id: 'knowledge-agent',
    name: 'Knowledge Agent',
    price: 495,
    interval: 'month',
    features: [
      'Custom knowledge store',
      'Call Assistant and Content Specialist',
      'Unlimited team members',
      'Unlimited storage',
      'Google Workspace integration'
    ],
  },
];

const BillingPage: React.FC = () => {
  const [selectedTier, setSelectedTier] = useState<string>('tier-professional');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/check`, {
        credentials: 'include',
      });
      setIsAuthenticated(response.ok);
    } catch (error) {
      console.error('Authentication check failed:', error);
      setIsAuthenticated(false);
    }
  };

  const handleSubscribe = async (priceId: string) => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          priceId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { url } = await response.json();
      window.location.href = url;
      
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-portal-session`, {
        method: 'POST',
        credentials: 'include',
      });
      
      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  // Only show subscription management for authenticated users
  const renderManageSubscription = () => {
    if (!isAuthenticated || !currentSubscription) return null;
    
    return (
      <div className="mt-4 text-center">
        <button
          onClick={handleManageSubscription}
          className="text-indigo-600 hover:text-indigo-500"
          disabled={loading}
        >
          Manage existing subscription
        </button>
      </div>
    );
  };

  return (
    <div className="bg-gray-50 min-h-screen py-12 relative">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="absolute top-8 left-8">
          <a href="/app" className="flex items-center space-x-2">
            <img
              className="h-8 w-auto"
              src={require('../assets/Volte_Logo.png')}
              alt=""
            />
          </a>
        </div>

        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Get started with Volte
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Hire your AI Super Employee today!
          </p>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-1 sm:gap-6 sm:space-y-0 max-w-md mx-auto">
          {pricingTiers.map((tier) => (
            <div
              key={tier.id}
              className={`rounded-lg shadow-lg divide-y divide-gray-200 ${
                selectedTier === tier.id ? 'ring-2 ring-indigo-600' : ''
              }`}
            >
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-gray-900">{tier.name}</h3>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">${tier.price}</span>
                  <span className="text-base font-medium text-gray-500">/{tier.interval}</span>
                </p>
                <ul className="mt-6 space-y-4">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-base text-gray-700">{feature}</p>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handleSubscribe(tier.id)}
                  className="mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium bg-indigo-600 text-white hover:bg-indigo-700"
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Subscribe'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BillingPage; 
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AppShell from './components/AppShell';
import LandingPage from './components/LandingPage';
import './global.css';
import AuthCallback from './components/AuthCallback';
import ContentSpecialist from './components/ContentSpecialist';
import AdminDashboard from './components/AdminDashboard';
import Login from './components/Login'; // Make sure you have this component
import BillingPage from './components/BillingPage';
import TermsOfService from './components/TermsOfService';
import { PremiumProvider, usePremium } from './contexts/PremiumContext';
import PremiumModal from './components/PremiumModal';
import usePremiumModal from './hooks/usePremiumModal';
import KnowledgeStore from './components/KnowledgeStore';

function App() {
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);
  const { isOpen, close } = usePremiumModal();

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setAuthenticated(false);
      }
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  useEffect(() => {
    // Check authentication status on component mount
    const checkAuth = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/status`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setAuthenticated(data.status === 'authenticated');
        } else {
          setAuthenticated(false);
        }
      } catch (err) {
        console.error('Auth check error:', err);
        setAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  if (authenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <PremiumProvider>
        <div>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route 
              path="/login" 
              element={
                authenticated ? (
                  <Navigate to="/app" replace />
                ) : (
                  <Login setAuthenticated={setAuthenticated as React.Dispatch<React.SetStateAction<boolean>>} />
                )
              } 
            />
            <Route 
              path="/app" 
              element={
                authenticated ? (
                  <RequireAuth>
                    <AppShell onLogout={handleLogout} />
                  </RequireAuth>
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route 
              path="/auth-callback" 
              element={<AuthCallback setAuthenticated={setAuthenticated} />} 
            />
            <Route path="/content-specialist" element={<ContentSpecialist />} />
            <Route 
              path="/admin" 
              element={
                authenticated ? (
                  <AdminDashboard />
                ) : (
                  <Navigate to="/" replace />
                )
              } 
            />
            <Route 
              path="/billing" 
              element={<BillingPage />}
            />
            <Route 
              path="/knowledge" 
              element={
                authenticated ? (
                  <KnowledgeStore />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>

          <PremiumModal 
            isOpen={isOpen}
            onClose={close}
          />
        </div>
      </PremiumProvider>
    </Router>
  );
}

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isPremium, requirePremium } = usePremium();
  
  useEffect(() => {
    if (!isPremium) {
      requirePremium();
    }
  }, [isPremium]);

  return (
    <div className="relative">
      {children}
      
      {!isPremium && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm z-40" />
      )}
    </div>
  );
};

export default App;

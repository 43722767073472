import React from 'react';

interface PremiumModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
}

const PremiumModal: React.FC<PremiumModalProps> = ({
  isOpen,
  onClose,
  title = "Upgrade to Continue",
  description = "Ready to supercharge your workforce with AI? Set up your subscription today and start building your own AI agents."
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md p-6 m-4">
        {/* Premium Icon */}
        <div className="mb-4 flex justify-center">
          <div className="p-3 bg-yellow-100 dark:bg-yellow-900 rounded-full">
            <svg 
              className="w-8 h-8 text-yellow-600 dark:text-yellow-400"
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          </div>
        </div>

        {/* Content */}
        <h2 className="text-2xl font-bold text-center mb-4 text-gray-900 dark:text-white">
          {title}
        </h2>
        <p className="text-center mb-6 text-gray-600 dark:text-gray-300">
          {description}
        </p>

        {/* Buttons */}
        <div className="flex flex-col gap-3">
          <button
            onClick={() => window.open('https://buy.stripe.com/5kA7tB9K7c8i7FS7ss', '_blank')}
            className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition-colors"
          >
            Upgrade to Premium
          </button>
          <button
            onClick={onClose}
            className="w-full py-2 px-4 bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-800 dark:text-gray-200 font-semibold rounded-lg transition-colors"
          >
            Maybe Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default PremiumModal; 
import React, { createContext, useContext, useState, useEffect } from 'react';
import usePremiumModal from '../hooks/usePremiumModal';

interface PremiumContextType {
  isPremium: boolean;
  checkPremiumStatus: () => Promise<void>;
  requirePremium: () => boolean;
}

const PremiumContext = createContext<PremiumContextType | undefined>(undefined);

export const PremiumProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isPremium, setIsPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { open } = usePremiumModal();

  const checkPremiumStatus = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subscription`, {
        credentials: 'include',
      });
      
      if (!response.ok) {
        setIsPremium(false);
        return;
      }

      const data = await response.json();
      console.log('Subscription data:', data);
      
      // Check both has_subscription and status
      setIsPremium(data.has_subscription && data.status === 'active');
      
    } catch (error) {
      console.error('Error checking premium status:', error);
      setIsPremium(false);
    } finally {
      setIsLoading(false);
    }
  };

  const requirePremium = () => {
    if (isLoading) return true;
    if (!isPremium) {
      open();
      return false;
    }
    return true;
  };

  useEffect(() => {
    checkPremiumStatus();
  }, []);

  return (
    <PremiumContext.Provider value={{ isPremium, checkPremiumStatus, requirePremium }}>
      {children}
    </PremiumContext.Provider>
  );
};

export const usePremium = () => {
  const context = useContext(PremiumContext);
  if (context === undefined) {
    throw new Error('usePremium must be used within a PremiumProvider');
  }
  return context;
}; 
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface AuthCallbackProps {
    setAuthenticated: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const AuthCallback: React.FC<AuthCallbackProps> = ({ setAuthenticated }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [authenticated, setAuthenticatedState] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const authSuccess = params.get('auth_success');

        if (authSuccess === 'true') {
            // Authentication was successful
            // You might want to fetch user data or JWT token here
            setAuthenticatedState(true); // Assuming you have a function to set authentication state
            navigate('/app');
        } else {
            console.error('Authentication failed');
            navigate('/login', { state: { error: 'Authentication failed. Please try again.' } });
        }
    }, [location, navigate, setAuthenticatedState]);

    return <div>Authenticating...</div>;
};

export default AuthCallback;
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

interface User {
  id: number;
  email: string;
}

interface ChatHistory {
  id: number;
  user_id: number;
  user_email: string;
  query: string;
  response: string;
  timestamp: string;
}

const AdminDashboard: React.FC = () => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalChats, setTotalChats] = useState(0);
  const [chatHistory, setChatHistory] = useState<ChatHistory[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [timeSaved, setTimeSaved] = useState<number>(0);

  const checkAdminStatus = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/status`, {
        credentials: 'include',
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch auth status');
      }

      const data = await response.json();
      if (data.workspace?.role === 'admin') {
        setIsAdminAuthenticated(true);
        setError(null);
      } else {
        setError('Unauthorized: Admin access required');
        setIsAdminAuthenticated(false);
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
      setError('Failed to verify admin status');
      setIsAdminAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    checkAdminStatus();
  }, [checkAdminStatus]);

  const fetchData = useCallback(async () => {
    try {
      const [usersResponse, chatsResponse, historyResponse, timeResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_API_URL}/api/admin/users`, { credentials: 'include' }),
        fetch(`${process.env.REACT_APP_API_URL}/api/admin/chats`, { credentials: 'include' }),
        fetch(`${process.env.REACT_APP_API_URL}/api/admin/chat-history`, { credentials: 'include' }),
        fetch(`${process.env.REACT_APP_API_URL}/api/admin/time-saved`, { credentials: 'include' }),
      ]);

      if (!usersResponse.ok || !chatsResponse.ok || !historyResponse.ok || !timeResponse.ok) {
        throw new Error('Unauthorized or server error');
      }

      const [usersData, chatsData, historyData, timeData] = await Promise.all([
        usersResponse.json(),
        chatsResponse.json(),
        historyResponse.json(),
        timeResponse.json(),
      ]);

      setTotalUsers(usersData.total_users || 0);
      setTotalChats(chatsData.total_chats || 0);
      setChatHistory(historyData.chat_history || []);
      setUsers(usersData.users || []);
      setTimeSaved(timeData.time_saved || 0);
      setError(null);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setError('Failed to fetch data. Please ensure you are logged in and have admin privileges.');
      setIsAdminAuthenticated(false);
      // Reset states to default values in case of error
      setTotalUsers(0);
      setTotalChats(0);
      setChatHistory([]);
      setUsers([]);
      setTimeSaved(0);
    }
  }, []);

  useEffect(() => {
    if (isAdminAuthenticated) {
      fetchData();
    }
  }, [isAdminAuthenticated, fetchData]);

  const downloadCSV = () => {
    const headers = ['UserID', 'UserEmail', 'Query', 'Response', 'Timestamp'];
    const csvRows = [
      headers.join(','), // Add headers
      ...chatHistory.map(chat => [
        chat.user_id,
        chat.user_email,
        `"${chat.query.replace(/"/g, '""')}"`,
        `"${chat.response.replace(/"/g, '""')}"`,
        new Date(chat.timestamp).toLocaleString()
      ].join(','))
    ];

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'chat_history.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!isAdminAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Access Denied</h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button
            onClick={() => window.location.href = '/app'}
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
          >
            Return to App
          </button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Error</h2>
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold">Admin Dashboard</h1>
        <div>
          <button
            onClick={() => window.location.href = '/app'}
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mr-2"
          >
            Go to App
          </button>
          <button
            onClick={downloadCSV}
            className="bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-gray-700 mr-2"
          >
            Export
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-3 gap-4 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">Total Users</h2>
          <p className="text-4xl font-bold">{totalUsers}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">Total Chats</h2>
          <p className="text-4xl font-bold">{totalChats}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">Time Saved</h2>
          <p className="text-4xl font-bold">{timeSaved} hrs</p>
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="userFilter" className="mr-2">Filter by User:</label>
        <select
          id="userFilter"
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          className="px-3 py-2 border rounded-md"
        >
          <option value="">All Users</option>
          {users && users.length > 0 ? (
            users.map((user) => (
              <option key={user.id} value={user.email}>
                {user.email}
              </option>
            ))
          ) : (
            <option value="" disabled>No users available</option>
          )}
        </select>
      </div>

      <div className="bg-white rounded-lg shadow-md overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">User ID</th>
              <th className="px-4 py-2">Email</th>
              <th className="px-4 py-2">Question</th>
              <th className="px-4 py-2">AI Answer</th>
              <th className="px-4 py-2">Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {chatHistory.slice().map((chat) => (
              <tr key={chat.id} className="border-b">
                <td className="px-4 py-2">{chat.user_id}</td>
                <td className="px-4 py-2">{chat.user_email}</td>
                <td className="px-4 py-2">{chat.query}</td>
                <td className="px-4 py-2">{chat.response}</td>
                <td className="px-4 py-2">{new Date(chat.timestamp).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashboard;

import React, { useState } from 'react';
import { SparklesIcon, DocumentDuplicateIcon, Bars3Icon, XMarkIcon,  BuildingOffice2Icon, EnvelopeIcon, PhoneIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/24/solid'

const features = [
    {
      name: 'Collect your documents',
      description:
        'Prepare your company\'s policies, pricing, processes, marketing materials, guidelines, and other documents to add to the knowledge store.',
      icon: DocumentDuplicateIcon,
    },
    {
      name: 'Create your knowledge store',
      description:
        'Simply drag and drop files into your knowledge store and your agent will automatically index them. The more documents you add, the better the agent gets.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Use your agent\'s skills',
      description:
        'Once your knowledge store is created, your agent will be ready to empower your teams. Ask questions, generate content, automate tasks, and more.',
      icon: SparklesIcon,
    }
  ]

//   const faqs = [
//     {
//       id: 1,
//       question: "Is my data private?",
//       answer:
//         "Yes, your data is stored in your own private knowledge store accesssible only to your team."
//     },
//     {
//       id: 2,
//       question: "What are the best ways to use Volte?",
//       answer:
//         "Our customers use Volte for all kinds of tasks, including answering questions, generating content, and automating tasks."
//     },
//   ]

  const navigation = [
    { name: 'Features', href: 'features' },
    { name: 'How it works', href: 'how-it-works' },
    { name: 'Contact', href: 'contact' },
  ]

const LandingPage: React.FC = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const scrollToSection = (sectionId: string) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        setMobileMenuOpen(false); // Close mobile menu after clicking
    };

    return (
        <div className="bg-gray-900">
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Volte Technologies</span>
                            <img
                                className="h-8 w-auto"
                                src={require('../assets/Volte_Logo_white.png')}
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <button
                                key={item.name}
                                onClick={() => scrollToSection(item.href)}
                                className="text-sm font-semibold leading-6 text-white cursor-pointer"
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <a href="/login" className="text-sm font-semibold leading-6 text-white">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
                {mobileMenuOpen && (
                    <div className="lg:hidden" role="dialog" aria-modal="true">
                        <div className="fixed inset-0 z-50"></div>
                        <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                            <div className="flex items-center justify-between">
                                <a href="#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Volte Technologies</span>
                                    <img
                                        className="h-8 w-auto"
                                        src={require('../assets/Volte_Logo_white.png')}
                                        alt=""
                                    />
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-gray-400"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/25">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) => (
                                            <button
                                                key={item.name}
                                                onClick={() => scrollToSection(item.href)}
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800 cursor-pointer"
                                            >
                                                {item.name}
                                            </button>
                                        ))}
                                    </div>
                                    <div className="py-6">
                                        <a
                                            href="/login"
                                            className="block rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                                        >
                                            Log in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </header>

            {/* Hero Section */}
            <div className="relative isolate min-h-screen flex items-center">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-3xl text-center">
                        <h1 className="text-balance text-5xl text-7xl font-bold tracking-tight text-white mx-auto">
                            Your AI-powered super employee
                        </h1>
                        <p className="mt-8 text-2xl sm:text-2xl leading-8 text-gray-300 mx-auto">
                            Volte gives everyone at your company instant access to your organization's knowledge.
                        </p>
                        <div className="mt-12 flex items-center justify-center gap-x-6">
                            <button
                                onClick={() => scrollToSection('contact')}
                                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                            >
                                Get started
                            </button>
                            <button
                                onClick={() => scrollToSection('features')}
                                className="text-md font-semibold leading-6 text-white cursor-pointer"
                            >
                                Learn more <span aria-hidden="true">→</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Use Cases / Success Stories */}
            <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
            <figure className="mx-auto max-w-2xl">
                <p className="sr-only">5 out of 5 stars</p>
                <div className="flex gap-x-1 text-indigo-600">
                <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
                <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
                <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
                <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
                <StarIcon aria-hidden="true" className="h-5 w-5 flex-none" />
                </div>
                <blockquote className="mt-10 text-xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
                <p>
                    “We save hundreds of hours every week by using Volte's Knowledge Agent. Our doctors and staff can now focus more on patient care, and not on searching for information.”
                </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                <img
                    alt=""
                    src={require('../assets/doctor-max-rubin.png')}
                    className="h-12 w-12 rounded-full bg-gray-50"
                />
                <div className="text-sm leading-6">
                    <div className="font-semibold text-gray-900">Dr. Max Rubin</div>
                    <div className="mt-0.5 text-gray-600">President, Brooklyn Audiology Associates</div>
                </div>
                </figcaption>
            </figure>
            </section>

            {/* Features */}
            <section id="features" className="py-20 bg-white">
                <div className="bg-gray-50 py-24 sm:py-32">
                    <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
                        <h2 className="text-center text-base/7 font-semibold text-indigo-600">Instant Answers</h2>
                        <p className="mx-auto mt-2 max-w-xl text-pretty text-center text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl">
                        AI-powered Q&A for your teams
                        </p>
                        <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
                        <div className="relative lg:row-span-2">
                            <div className="absolute inset-px rounded-lg bg-white lg:rounded-l-[2rem]"></div>
                            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                            <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                Chat-first experience
                                </p>
                                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                Volte's Knowledge Agent is designed to be chat-first, meaning your teams can ask questions in natural language.
                                </p>
                            </div>
                            <div className="mx-8 my-4 border-t border-gray-200"></div>
                            <div className="relative min-h-[20rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                                <div className="flex items-center justify-center h-full p-2">
                                    <img
                                        className="max-w-[97%] max-h-[97%] object-contain"
                                        src={require('../assets/Messages_trimmed.png')}
                                        alt="Chat interface"
                                    />
                                </div>
                            </div>
                            </div>
                            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
                        </div>
                        <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
                            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem]"></div>
                            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
                            <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                Lightning fast
                                </p>
                                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                Built on cutting-edge foundational models and hardware, Volte's Knowledge Agent responds in milliseconds.
                                </p>
                            </div>
                            {/* <div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                                <img
                                className="w-full max-lg:max-w-xs"
                                src="https://tailwindui.com/plus/img/component-images/bento-03-performance.png"
                                alt=""
                                />
                            </div> */}
                            </div>
                            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
                        </div>
                        <div className="relative max-lg:row-start-1">
                            <div className="absolute inset-px rounded-lg bg-white"></div>
                            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                            <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">Private</p>
                                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                Your knowledge store is private which means your teams are the only ones who can access it.
                                </p>
                            </div>
                            <div className="flex flex-1 items-center [container-type:inline-size] max-lg:py-6 lg:pb-2">
                                <img
                                className="h-[min(152px,40cqw)] object-cover object-center"
                                src="https://tailwindui.com/plus/img/component-images/bento-03-security.png"
                                alt=""
                                />
                            </div>
                            </div>
                            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
                        </div>
                        <div className="relative lg:row-span-2">
                            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
                            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                            <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                                <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                Custom skills
                                </p>
                                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                Leverage Volte's multi-skill framework to create custom agent workflows
                                </p>
                            </div>
                            <div className="relative min-h-[30rem] w-full grow">
                                <div className="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-gray-900 shadow-2xl">
                                <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                                    <div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">
                                    <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                                        Call Assistant
                                    </div>
                                    <div className="border-r border-gray-600/10 px-4 py-2">Marketing Specialist</div>
                                    </div>
                                </div>
                                <div className="px-6 pb-14 pt-6">{/* Your code example */}</div>
                                </div>
                            </div>
                            </div>
                            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* How it works */}
            <section id="how-it-works" className="bg-white">
                <div className="bg-white py-16 sm:py-24">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-base font-semibold leading-7 text-indigo-600">How it works</h2>
                        <p className="mx-auto mt-2 text-pretty text-center text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl">
                            Add knowledge. Ask questions. Generate content.
                        </p>
                        <p className="mt-6 text-xl leading-8 text-gray-600">
                            Volte's Knowledge Agent is designed to be simple for any team to use and manage. 
                        </p>
                        </div>
                        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                            {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="flex items-center text-lg gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                <feature.icon aria-hidden="true" className="h-5 w-5 flex-none text-indigo-600" />
                                {feature.name}
                                </dt>
                                <dd className="mt-4 text-md flex flex-auto flex-col text-base leading-7 text-gray-600">
                                <p className="flex-auto">{feature.description}</p>
                                </dd>
                            </div>
                            ))}
                        </dl>
                        </div>
                    </div>
                </div>
            </section>

            {/* FAQ
            <section id="faq" className="bg-white">
                <div className="bg-white">
                    <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                        <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600">
                        Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
                        <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                            sending us an email
                        </a>{' '}
                        and we’ll get back to you as soon as we can.
                        </p>
                        <div className="mt-20">
                        <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
                            {faqs.map((faq) => (
                            <div key={faq.id}>
                                <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                            </div>
                            ))}
                        </dl>
                        </div>
                    </div>
                    </div>
            </section> */}

            {/* Call-to-Action */}
            <section id="contact" className="py-10 bg-white text-white">
            <div className="relative isolate bg-white">
                <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                    <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                        <svg
                            aria-hidden="true"
                            className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                        >
                            <defs>
                            <pattern
                                x="100%"
                                y={-1}
                                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                width={200}
                                height={200}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M130 200V.5M.5 .5H200" fill="none" />
                            </pattern>
                            </defs>
                            <rect fill="white" width="100%" height="100%" strokeWidth={0} />
                            <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                            <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                            </svg>
                            <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
                        </svg>
                        </div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">Ready to get started?</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                        We'd love to partner with you! Please reach out and describe your use case, and we'll get back to you as soon as possible.
                        </p>
                        <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                        <div className="flex gap-x-4">
                            <dt className="flex-none">
                            <span className="sr-only">Address</span>
                            <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                            </dt>
                            <dd>
                            New York, NY
                            <br />
                            </dd>
                        </div>
                        <div className="flex gap-x-4">
                            <dt className="flex-none">
                            <span className="sr-only">Telephone</span>
                            <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                            </dt>
                            <dd>
                            <a href="tel:+1 (646) 543-9711" className="hover:text-gray-900">
                                +1 (646) 543-9711
                            </a>
                            </dd>
                        </div>
                        <div className="flex gap-x-4">
                            <dt className="flex-none">
                            <span className="sr-only">Email</span>
                            <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                            </dt>
                            <dd>
                            <a href="mailto:hello@voltetech.com" className="hover:text-gray-900">
                                hello@voltetech.com
                            </a>
                            </dd>
                        </div>
                        </dl>
                    </div>
                    </div>
                    <form action="#" method="POST" className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            First name
                            </label>
                            <div className="mt-2.5">
                            <input
                                id="first-name"
                                name="first-name"
                                type="text"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            Last name
                            </label>
                            <div className="mt-2.5">
                            <input
                                id="last-name"
                                name="last-name"
                                type="text"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                            Email
                            </label>
                            <div className="mt-2.5">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                            Phone number
                            </label>
                            <div className="mt-2.5">
                            <input
                                id="phone-number"
                                name="phone-number"
                                type="tel"
                                autoComplete="tel"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                            Message
                            </label>
                            <div className="mt-2.5">
                            <textarea
                                id="message"
                                name="message"
                                rows={4}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue={''}
                            />
                            </div>
                        </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Send message
                        </button>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="py-6 bg-white">
                <div className="container mx-auto text-center">
                    <div className="space-x-4">
                        <a href="/terms" className="hover:underline">Terms of Service</a>
                        <a href="/privacy" className="hover:underline">Privacy Policy</a>
                    </div>
                    <p className="mt-4">&copy; {new Date().getFullYear()} Volte Technologies, LLC. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;

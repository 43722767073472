import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';

interface MessageProps {
  sender: string;
  avatar: string;
  name: string;
  content: string;
  isLast: boolean;
}

function Message({ sender, avatar, name, content, isLast }: MessageProps) {
  const isUser = sender === 'user';
  
  // Update the name to display "User" if sender is 'user' and name is empty
  const displayName = isUser && !name ? 'User' : name;

  return (
    <div className={`message ${isUser ? 'user-message' : 'bot-message'}`}>
      <div className="avatar">
        {isUser ? ( // Conditional rendering for user avatar
          <div className="user-initial">{name ? name.charAt(0) : 'U'}</div> // Display user's initial
        ) : (
          <img src={avatar} alt={`${displayName}'s avatar`} /> // Display bot avatar
        )}
      </div>
      <div className="message-body">
        <div className="name">{displayName}</div>
        <div className="content">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeSanitize]}
          >
            {content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export default Message;
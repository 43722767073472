import React, { useState, useEffect, useRef } from 'react';
import NavigationMenu from './NavigationMenu';
import Chat from './Chat';

function AppShell({ onLogout }: { onLogout: () => void }) {
  const [activeTab, setActiveTab] = useState('chat');
  const [userName, setUserName] = useState('Unknown User');
  const [userInitial, setUserInitial] = useState('U');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/status`, {
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setUserName(data.name || 'Unknown User');
          setUserInitial((data.name && data.name.charAt(0)) || 'U');
          setIsAdmin(data.workspace?.role === 'admin');
        }
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    };

    fetchUserName();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <NavigationMenu onLogout={onLogout} />
      <div className="flex flex-col flex-grow overflow-hidden">
        <div className="flex border-b justify-between items-center h-16">
          <div className="flex h-full">
            <button
              className={`px-6 relative h-full flex items-center transition-colors
                ${activeTab === 'chat' ? 'text-blue-500' : 'text-gray-600 hover:text-gray-900'}`}
              onClick={() => setActiveTab('chat')}
            >
              Call Assistant
              {activeTab === 'chat' && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-500 transition-all" />
              )}
            </button>
            <button
              className={`px-6 relative h-full flex items-center transition-colors
                ${activeTab === 'content' ? 'text-blue-500' : 'text-gray-600 hover:text-gray-900'}`}
              onClick={() => setActiveTab('content')}
            >
              Content Specialist
              {activeTab === 'content' && (
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-500 transition-all" />
              )}
            </button>
          </div>
          
          <div className="relative mr-4" ref={dropdownRef}>
            <button
              className="w-8 h-8 rounded-full bg-blue-500 text-white flex items-center justify-center"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              {userInitial}
            </button>
            
            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                <div className="px-4 py-2 text-sm text-gray-700 border-b">
                  {userName}
                </div>
                {isAdmin && (
                  <a
                    href="/admin"
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Admin
                  </a>
                )}
                <button
                  onClick={onLogout}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="flex-grow overflow-hidden">
          {activeTab === 'chat' && (
            <Chat 
              apiEndpoint="/api/chat" 
              placeholder="Message..." 
              botName="Call Assistant"
            />
          )}
          {activeTab === 'content' && (
            <Chat 
              apiEndpoint="/api/generate_content" 
              placeholder="Type your content request here..." 
              botName="Content Specialist"
            />
          )}
          {activeTab === 'knowledge' && <div>Knowledge Store Placeholder</div>}
        </div>
      </div>
    </div>
  );
}

export default AppShell;
